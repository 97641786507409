<script>
import Layout from "@/views/layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import AreaRole from "./components/work-area-role.vue";
import MagicTable from "@/components/MagicTable/MagicTable";
import Swal from "sweetalert2";

/**
 * Add-product component
 */
export default {
  page: {
      title: "Work Area",
      meta: [
      {
          name: "description",
          content: appConfig.description,
      },
      ],
  },
  components: {
    Layout,
    PageHeader,
    AreaRole,
    MagicTable,
  },
  data() {
    return {
      title: "Work Area",
      fetch_url: "/area/index",
      items: [
        {
          text: "Work Area",
        },
        {
          text: "Listing",
          active: true,
        },
      ],
      workareas: null,
      areas: [],
      arearole: null,
      isShowRole: false,
    };
  },
  middleware: "authentication",

  methods: {
    showRole(arearole) {
      this.arearole = arearole;
      this.isShowRole = true;
    },
    updateRecords(records) {
      this.areas = records;

      console.log(this.areas, 'work area');
    },
    deleteArea(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      })
        .then((result) => {
          if (result.value) {
            this.$axios
              .delete("/area/delete/" + id)
              .then((response) => {
                this.arearole = [];
                let data = response.data;
                this.areas = this.areas.filter((item) => item.id != id);
                Swal.fire("Deleted!", data.message, "success");
              })
              .catch((error) => {
                this.handleErrorResponse(error.response, "error");
              })
              .finally(function () {});
          }
        })
        .catch((error) => {})
        .finally(function () {});
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title" />

    <div class="card">
      <div class="card-body">
        <router-link to="/workarea/create" ><b-button class="gradientColor border-0 radius-20 px-4 float-end"> Create Work Area </b-button></router-link>
        <magic-table
          :fetch_url_prop="fetch_url"
          @recordsUpdated="updateRecords"
        >
          <template v-slot:table>
            <div class="row mt-4">
              <div class="col-lg-12">
                <div class="table-responsive table-nowrap">
                  <table class="table mb-0 table-bordered">
                    <thead>
                      <tr>
                        <th class="f-14 fw-semiBold roboto">Area Name</th>
                        <th class="f-14 fw-semiBold roboto">Location Name</th>
                        <th class="f-14 fw-semiBold roboto">Area Role Count</th>
                        <th class="f-14 fw-semiBold roboto">Area Role</th>
                        <th class="f-14 fw-semiBold roboto">Created At</th>
                        <th class="f-14 fw-semiBold roboto">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template >
                        <tr v-for="(area, key) in areas" :key="key">
                          <td scope="row" class="f-14 roboto pColor">
                            {{ area.name }}
                          </td>
                          <td scope="row" class="f-14 roboto pColor">
                            {{ area.location.name }}
                          </td>
                          <td scope="row" class="f-14 roboto pColor">
                            {{ area.area_role.length }}
                          </td>
                          <td scope="row" class="f-14 roboto pColor">
                            <span v-for="(areaRole, index) in area.area_role" :key="index">
                              {{ areaRole.name }} |
                            </span>
                          </td>
                          <td scope="row" class="f-14 roboto pColor">
                            {{ area.created_at_formatted }}
                          </td>
                          <td scope="row" class="f-14 roboto pColor">
                            <ul class="d-flex list-inline mb-0">
                              <li class="list-inline-item">
                                <router-link
                                  v-b-tooltip.hover
                                  title="Edit"
                                  :to="{
                                    name: 'Edit_Area',
                                    params: { area_id: area.id },
                                  }"
                                >
                                  <i class="uil uil-pen font-size-18"></i>
                                </router-link>
                              </li>
                              <li class="list-inline-item">
                                <a
                                  href="javascript:void(0);"
                                  class="px-2 text-danger"
                                  v-b-tooltip.hover
                                  @click="deleteArea(area.id)"
                                  title="Delete"
                                >
                                  <i class="uil uil-trash-alt font-size-18"></i>
                                </a>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </template>
        </magic-table>
      </div>
    </div>

    <AreaRole v-if="isShowRole" :area_roles="arearole"></AreaRole>
  </Layout>
</template>
